<template>
  <div class="wrap">
    <div class="sider">
      <img :src="corpInfo.corpPic" class="logo" alt="" />
      <div class="sider-menu">
        <div
          class="menu-item"
          @click="menuClick(item, index)"
          v-for="(item, index) in menuList"
          :key="index"
          :class="activeIndex === index ? 'menu-item-act' : ''"
        >
          <div class="iconfont" :class="item.icon"></div>
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="session">
      <div class="session-header">
        <div class="header-left">
          <reload-outlined style="margin-right: 20px;cursor: pointer;" @click="refresh" />
          <div>
            <a-breadcrumb :routes="routes">
            <template #itemRender="{ route, paths }">
              <span
                v-if="
                  routes.indexOf(route) === routes.length - 1 ||
                  routes.indexOf(route) === routes.length - 2
                "
              >
                <span v-if="route.title === '首页'">
                  <HomeOutlined></HomeOutlined>
                </span>
                <span v-else> {{ route.title }}</span>
              </span>
              <router-link
                @click="routerClick(route)"
                v-else
                :to="{
                  path: `${basePath}${paths[paths.length - 1]}`,
                  query: { corpId: corpId }
                }"
              >
                <span v-if="route.title === '首页'">
                  <HomeOutlined></HomeOutlined>
                </span>
                <span v-else> {{ route.title }}</span>
              </router-link>
            </template>
          </a-breadcrumb>
          </div>
        </div>
        <div class="header-right">
          <a-dropdown
            placement="bottomCenter"
            :overlay-style="{ minWidth: '120px' }"
          >
            <div class="ud-admin-header-avatar">
              <a-avatar :src="userInfo.avatar" />
              <span style="margin-left: 5px; cursor: pointer"
                >{{ userInfo.nickname }}&nbsp;</span
              >
              <down-outlined />
            </div>
            <template #overlay>
              <a-menu @click="onUserDropClick">
                <a-menu-item key="back">
                  <div class="ud-cell">
                    <rollback-outlined />
                    <div class="ud-cell-content" >返回中心</div>
                  </div>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item key="logout">
                  <div class="ud-cell">
                    <logout-outlined />
                    <div class="ud-cell-content">
                      {{ $t('layout.header.logout') }}
                    </div>
                  </div>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
      <div class="content">
        <div
          class="content-sider"
          v-if="
            menuList[activeIndex].children &&
            menuList[activeIndex].children.length > 0
          "
        >
          <a-menu
            mode="inline"
            v-model:selectedKeys="selectedKeys"
            :open-keys="openKeys"
            @click="handlerClick"
            @openChange="onOpenChange"
          >
            <template
              v-for="item in menuList[activeIndex].children"
              :key="item.key"
            >
              <template v-if="!item.children">
                <a-menu-item :key="item.key">
                  <template #icon>
                    <PieChartOutlined />
                  </template>
                  {{ item.title }}
                </a-menu-item>
              </template>
              <template v-else>
                <a-sub-menu :key="item.key" :title="item.title">
                  <a-menu-item
                    v-for="subMenu in item.children"
                    :key="subMenu.key"
                    ><template #icon>
                      <PieChartOutlined />
                    </template>
                    {{ subMenu.title }}</a-menu-item
                  >
                </a-sub-menu>
              </template>
            </template>
          </a-menu>
        </div>
        <div class="content-right">
          <div class="router-view"><router-view v-if="showRouterView"></router-view></div>
          <div class="footer">&copy;远丞2023</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as corpApi from '@/api/corp/corp.js'
import {
  PieChartOutlined,
  DownOutlined,
  HomeOutlined,
  RollbackOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  ReloadOutlined
} from '@ant-design/icons-vue'
import { createVNode } from 'vue'
export default {
  components: {
    PieChartOutlined,
    DownOutlined,
    HomeOutlined,
    RollbackOutlined,
    LogoutOutlined,
    ReloadOutlined
  },
  data() {
    return {
      userInfo: this.$store.state.user.user,
      corpId: null,
      activeIndex: 0,
      rootSubmenuKeys: [],
      openKeys: [],
      selectedKeys: [],
      showRouterView:true,
      menuList: [
        // {
        //   title: '首页',
        //   icon: 'icon-shouyefill',
        //   key: 'home',
        //   path: '/app/center/home',
        //   children: []
        // },
        {
          title: '会员',
          icon: 'icon-huiyuan21',
          key: 'member',
          path: '/app/member',
          children: [
            {
              title: '会员管理',
              key: 'memberManage',
              path: '/app/member/manage',
              children: [
                {
                  title: '会员列表',
                  icon: 'icon-danlieliebiao',
                  key: '1',
                  path: '/app/member/manage/index'
                },
                // {
                //   title: '会员等级',
                //   icon: 'icon-huiyuan1',
                //   key: '2',
                //   path: '/app/member/manage/level'
                // },
                // {
                //   title: '卡面设置',
                //   icon: 'icon-huiyuan1',
                //   key: '21',
                //   path: '/app/member/manage/card'
                // }
              ]
            },
            // {
            //   title: '余额记录',
            //   key: 'balanceRecord',
            //   path: '/app/balance',
            //   children: [
            //     {
            //       title: '充值记录',
            //       icon: 'icon-danlieliebiao',
            //       key: '15',
            //       path: '/app/balance/recharge'
            //     },
            //     {
            //       title: '余额明细',
            //       icon: 'icon-huiyuan1',
            //       key: '16',
            //       path: '/app/balance/detail'
            //     }
            //   ]
            // },
            // {
            //   title: '积分记录',
            //   key: 'integrateRecord',
            //   path: '/app/integrate',
            //   children: [
            //     {
            //       title: '充值记录',
            //       icon: 'icon-danlieliebiao',
            //       key: '17',
            //       path: '/app/integrate/recharge'
            //     },
            //     {
            //       title: '积分明细',
            //       icon: 'icon-huiyuan1',
            //       key: '18',
            //       path: '/app/integrate/detail'
            //     }
            //   ]
            // },
            {
              title: '申请管理',
              key: 'apply',
              path: '/app/apply',
              children: [
                {
                  title: '申请列表',
                  icon: 'icon-danlieliebiao',
                  key: '19',
                  path: '/app/apply/index'
                },
                // {
                //   title: '申请设置',
                //   icon: 'icon-huiyuan1',
                //   key: '20',
                //   path: '/app/apply/setting'
                // }
              ]
            }
          ]
        },
        {
          title: '活动',
          icon: 'icon-zhaohuodong',
          key: 'activity',
          path: '/app/activity/manage/',
          children: [
            {
              title: '活动管理',
              key: 'sub1',
              path: '/app/activity/manage',
              children: [
                {
                  title: '活动列表',
                  icon: 'icon-danlieliebiao',
                  key: '4',
                  path: '/app/activity/manage/index'
                },
                // {
                //   title: '报名模板',
                //   icon: 'icon-huiyuan1',
                //   key: '5',
                //   path: '/app/activity/manage/form'
                // },
                // {
                //   title: '活动分类',
                //   icon: 'icon-shenqingguanli',
                //   key: '6',
                //   path: '/app/activity/manage/category'
                // },
                {
                  title: '活动模板',
                  icon: 'icon-shenqingguanli',
                  key: '7',
                  path: '/app/activity/manage/template'
                }
              ]
            },
            {
              title: '现场管理',
              key: 'sub2',
              path: '/app/scene',
              children: [
                // {
                //   title: '现场列表',
                //   icon: 'icon-danlieliebiao',
                //   key: '8',
                //   path: '/app/scene/index'
                // },
                {
                  title: '大屏列表',
                  icon: 'icon-danlieliebiao',
                  key: '18',
                  path: '/app/scene/screen/index'
                }
              ]
            }
          ]
        },
        
        // {
        //   title: '门店',
        //   icon: 'icon-mendian',
        //   key: 'store',
        //   path: '/app/store',
        //   children: [
        //     {
        //       title: '门店列表',
        //       icon: 'icon-danlieliebiao',
        //       key: '14',
        //       path: '/app/store/shop'
        //     }
        //   ]
        // },
        // {
        //   title: '商品',
        //   icon: 'icon-shangpinguanli-jianying',
        //   key: 'goods',
        //   path: '/app/goods',
        //   children: [
        //     {
        //       title: '商品列表',
        //       icon: 'icon-danlieliebiao',
        //       key: '23',
        //       path: '/app/goods/index'
        //     },
        //     {
        //       title: '商品分类',
        //       icon: 'icon-danlieliebiao',
        //       key: '24',
        //       path: '/app/goods/category'
        //     },
        //     {
        //       title: '服务承诺',
        //       icon: 'icon-danlieliebiao',
        //       key: '25',
        //       path: '/app/goods/promise'
        //     },
        //     {
        //       title: '商品评价',
        //       icon: 'icon-danlieliebiao',
        //       key: '26',
        //       path: '/app/goods/appraise'
        //     }
        //   ]
        // },
        // {
        //   title: '订单',
        //   icon: 'icon-dingdanzhongxin',
        //   key: 'order',
        //   path: '/app/order',
        //   children: [
        //     {
        //       title: '订单列表',
        //       icon: 'icon-danlieliebiao',
        //       key: '27',
        //       path: '/app/order/index'
        //     },
        //     {
        //       title: '发货管理',
        //       icon: 'icon-danlieliebiao',
        //       key: '28',
        //       path: '/app/order/delivery'
        //     },
        //     {
        //       title: '售后管理',
        //       icon: 'icon-danlieliebiao',
        //       key: '29',
        //       path: '/app/order/refund'
        //     }
        //   ]
        // },
        // {
        //   title: '营销',
        //   icon: 'icon-yingxiao',
        //   key: 'marketing',
        //   path: '/app/marketing',
        //   children: [
        //     {
        //       title: '会员推广',
        //       key: 'distribute',
        //       path: '/app/marketing/distribute',
        //       children: [
        //         {
        //           title: '推广设置',
        //           icon: 'icon-danlieliebiao',
        //           key: '9',
        //           path: '/app/marketing/distribute/setting'
        //         },
        //         {
        //           title: '推广用户',
        //           icon: 'icon-danlieliebiao',
        //           key: '10',
        //           path: '/app/marketing/distribute/user'
        //         },
        //         {
        //           title: '推广订单',
        //           icon: 'icon-danlieliebiao',
        //           key: '11',
        //           path: '/app/marketing/distribute/order'
        //         }
        //       ]
        //     },
        //     {
        //       title: '优惠券',
        //       key: 'corpCoupon',
        //       path: '/app/corpCoupon',
        //       children: [
        //         {
        //           title: '优惠券列表',
        //           icon: 'icon-danlieliebiao',
        //           key: '22',
        //           path: '/app/corpCoupon/index'
        //         },
        //         {
        //           title: '领券记录',
        //           icon: 'icon-danlieliebiao',
        //           key: '30',
        //           path: '/app/corpCoupon/receive'
        //         },
        //         {
        //           title: '手动发放',
        //           icon: 'icon-danlieliebiao',
        //           key: '31',
        //           path: '/app/corpCoupon/give'
        //         }
        //       ]
        //     }
        //   ]
        // },
        // {
        //   title:'小程序',
        //   icon:'icon-xiaochengxu',
        //   key:'miniProgram',
        //   path:'/app/miniProgram',
        //   children:[
        //     {
        //       title:'导航管理',
        //       key:'32',
        //       icon:'icon-danlieliebiao',
        //       path:'/app/miniProgram/navigation'
        //     },
        //     {
        //       title:'横幅管理',
        //       key:'34',
        //       icon:'icon-danlieliebiao',
        //       path:'/app/miniProgram/banner'
        //     }
        //   ]
        // },
        // {
          // title: '设置',
          // icon: 'icon-shezhixitongshezhigongnengshezhishuxing',
          // key: 'setting',
          // path: '/app/dictionary',
          // children: [
            // {
            //   title: '支付管理',
            //   path: '/app/payment',
            //   key: 'paymentManage',
            //   children: [
            //     {
            //       title: '支付设置',
            //       icon: 'icon-danlieliebiao',
            //       key: '12',
            //       path: '/app/payment/setting'
            //     },
            //     {
            //       title: '支付模板',
            //       icon: 'icon-danlieliebiao',
            //       key: '13',
            //       path: '/app/payment/template'
            //     },
                
            //   ]
            // },
            // {
            //      title:'字典管理',
            //       key:'33',
            //       icon:'icon-danlieliebiao',
            //       path:'/app/dictionary'
            // }
          // ]
        // },
        {
          title: '返回中心',
          icon: 'icon-fanhui',
          key: 'back',
          path: '/app/corp',
          children: []
        }
      ],
      corpInfo: {},
      routes: [],
      basePath: '/'
    }
  },
  mounted() {
    this.corpId = this.$route.query.corpId
    this.$router.push({
      path: this.menuList[this.activeIndex].path,
      query: { corpId: this.corpId }
    })
    this.initBreadcrumb()
    this.getCorpInfo()
  },

  methods: {
    routerClick(route) {
      const { key } = route
      if (key === 'home') {
        this.selectedKeys = []
        this.activeIndex = 0
        this.openKeys = []
        this.rootSubmenuKeys = []
        this.initBreadcrumb()
      }
    },
    getCorpInfo() {
      corpApi
        .getCorpInfo(this.corpId)
        .then((res) => {
          if (res.code === 0) {
            this.corpInfo = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    menuClick(item, index) {      
      this.initMenuData(item.children)
      if (item.key !== 'back') {
        this.activeIndex = index
        this.navTo(item.path, { corpId: this.corpId })
        // this.$router.push({ path: item.path, query: { corpId: this.corpId } })
      } else {
        this.$router.push({ path: item.path })
      }
    },
    initMenuData(children) {
      this.initSelectKey(children)
      this.initBreadcrumb()
    },
    initSelectKey(children) {
      let selectKeys = []
      let openKeys = []
      let rootSubmenuKeys = []
      
      if (children && children.length > 0) {
        selectKeys.push(children[0].key)
        rootSubmenuKeys = children.map((val) => {
          return val.key
        })
        if (children[0].children && children[0].children.length > 0) {
          selectKeys.push(children[0].children[0].key)
          openKeys.push(children[0].key)
        }
        this.selectedKeys = selectKeys
        this.openKeys = openKeys
        this.rootSubmenuKeys = rootSubmenuKeys
      } else {
        this.selectedKeys = []
        this.openKeys = []
        this.rootSubmenuKeys = []
      }
      console.log('initSelectKey', children, this.selectedKeys, this.openKeys, this.rootSubmenuKeys)
    },
    handlerClick(item) {
      const { keyPath } = item
      this.selectedKeys = keyPath
      this.initBreadcrumb()
      let pathObj = this.getMenuPathObj(
        this.selectedKeys[this.selectedKeys.length - 1]
      )
      this.navTo(pathObj.path, { corpId: this.corpId })
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      )
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
    getMenuPathObj(key) {
      let obj = {}
      const result = this.platFn(this.menuList)
      obj = result.find((val) => {
        return val.key === key
      })
      return obj
    },
    platFn(list) {
      let res = []
      res = list.concat(
        ...list
          .map((item) => {
            if (item.children instanceof Array && item.children.length > 0) {
              return this.platFn(item.children)
            }
            return null
          })
          .filter((o) => o instanceof Array && o.length > 0)
      )
      return res
    },
    navTo(url, params) {
      this.$router.push({ path: url, query: params || {} })
    },
    initBreadcrumb() {
      let routes = []
      if (this.selectedKeys && this.selectedKeys.length > 0) {
        this.selectedKeys.forEach((key) => {
          const pathObj = this.getMenuPathObj(key)
          let obj = {
            path: pathObj.path,
            title: pathObj.title,
            key: pathObj.key
          }
          routes.push(obj)
        })
      }
      this.routes = [
        { path: '/app/center/home', title: '首页', key: 'home' }
      ].concat(routes)
    },
    onUserDropClick({ key }) {
      if (key === 'logout') {
        // 退出登录
        this.$confirm({
          title: this.$t('layout.logout.title'),
          content: this.$t('layout.logout.message'),
          icon: createVNode(ExclamationCircleOutlined),
          maskClosable: true,
          onOk: () => {
            // 清除缓存的token
            this.$store.dispatch('user/removeToken').then(() => {
              location.replace('/login') // 这样跳转避免再次登录重复注册动态路由
            })
          }
        })
      } else if (key === 'back') {
        // this.$router.push('/user/profile')
        this.navTo('/app/corp')
      } else if (key === 'password') {
        this.showPassword = true
      }
    },
    refresh(){
      this.showRouterView = false
      this.$nextTick(()=>{
        this.showRouterView = true
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
}
.sider {
  position: fixed;
  width: 100px;
  height: 100vh;
  flex-shrink: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: #3d475d;
  overflow: overlay;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .sider-menu {
    width: 100%;
    height: calc(100vh - 60px);
    overflow: overlay;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 5px;
      scrollbar-arrow-color: red;
    }
  }
  .logo {
    width: 60px;
    height: 60px;
  }
  .menu-item-act {
    background: #4772ff;
    color: #fff !important;
    border-radius: 5px;
    cursor: pointer;
  }
  .menu-item {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9198a9;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }
  .iconfont {
    font-size: 20px;
  }
}
.session {
  margin-left: 100px;
  width: calc(100% - 100px);
  height: 100vh;
  background: #f7f8fa;
  display: flex;
  flex-direction: column;
  .session-header {
    width: 100%;
    height: 66px;
    background: #fff;
    flex-shrink: 0;
    margin-bottom: 20px;
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    .header-left{
      display: flex;
      flex: 1;
      align-items: center;
    }
    .header-right {
      flex-shrink: 0;
    }
  }
  .content {
    flex: 1;
    height: calc(100vh - 66px - 20px);
    display: flex;
    .content-right {
      flex: 1;
      min-height: calc(100vh - 50px - 66px - 20px);
      overflow: hidden;
      overflow-y: scroll;
      .router-view {
        min-height: calc(100vh - 50px - 66px - 20px);
        :deep(.ud-body) {
          padding: 0 16px;
        }
      }
    }
    .content-sider {
      width: 250px;
      background: #fff;
      // padding: 20px;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      gap: 20px;
    }
  }
  .footer {
    height: 50px;
    text-align: center;
    line-height: 50px;
  }
}
</style>